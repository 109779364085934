<template>
  <div class="product-description  pb-10" v-if="tabs.length > 0">
    <div>
      <v-tabs
        v-model="selectedTab"
        slider-size="0"
        :show-arrows="$vuetify.breakpoint.xs"
      >
        <v-tab v-for="(tab, idx) in tabs" :key="idx">
          <h2 class="text-body-2 text-uppercase">
            {{ tab.title }}
          </h2>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
          <div
            v-for="item in tab.items"
            :key="item.title"
            :class="item.cssClass"
          >
            <h3
              class="text-body-2 text-uppercase font-weight-bold mb-1 mt-3"
              v-if="item.title"
            >
              {{ item.title }}
            </h3>
            <div
              class="text-body-2 content default--text"
              v-for="path in item.paths"
              :key="path.key"
            >
              <component
                :is="path.template || 'DefaultTpl'"
                :content="path.content"
                :append="path.append"
                class="component pb-4"
              />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import tabsConfig from "/public/product/descriptionTabsConfig.json";
import kebabCase from "lodash/kebabCase";
import {
  DefaultTpl,
  LabelsTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
} from "./templates";
export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    LabelsTpl,
    ItemsMapTpl,
    TableTpl,
    NameAndValueTpl,
    ValueTpl,
    WarningsTpl,
    ListTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: tabsConfig
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      // additional text at Ingredients tab end
      // let ingredientsTab = tabs.find(t => {
      //   return t.title === "Ingredienti e valori nutrizionali";
      // });
      // if (ingredientsTab) {
      //   ingredientsTab.items.push({
      //     paths: [
      //       { content: this.$t("productDescription.ingredientsAdditionalInfo") }
      //     ]
      //   });
      // }
      return tabs;
    }
  }
};
</script>

<style lang="scss">
.product-description {
  .description {
    &:last-child {
      border-bottom: none;
      .component {
        padding-bottom: 0 !important;
      }
    }
  }
  table {
    border-collapse: collapse;
  }
  table,
  tr,
  td,
  th {
    border: 1px solid var(--v-grey-base);
  }
  td,
  th {
    padding: 2px 6px;
  }
  th {
    background-color: var(--v-grey-lighten1);
  }
  .v-slide-group__content {
    border-bottom: 1px solid #d5d5d5;
  }
  .v-tabs {
    background-color: transparent;
    position: relative;
    top: 1px;
    z-index: 1;
    .v-tabs-bar {
      background-color: transparent;
    }
    .v-item-group {
      height: 46px;
    }
    .v-tab {
      color: $secondary !important;
      background-color: transparent;
      border-radius: 4px 4px 0 0;
    }
    .content {
      line-height: 22px !important;
      ul {
        padding-left: 0 !important;
      }
      li {
        list-style: none !important;
      }
    }
    .v-tab--active {
      border: 1px solid #d5d5d5;
      border-bottom: 2px solid $white;
      margin-bottom: -1px;
      background-color: $white;
      font-weight: bold;
      &:hover::before {
        opacity: 0 !important;
      }
    }
  }
  .v-tabs-items {
    background-color: $white;
    padding: 20px;
    .nutritional-info,
    .ingredients,
    .description,
    .producer_info,
    .other,
    .conservation_info,
    .package_info,
    .preparation_info {
      margin-bottom: 10px;
      .content {
        line-height: 22px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-expansion-panels {
      background-color: transparent;
      .v-expansion-panel--active > .v-expansion-panel-header {
        min-height: 48px;
      }
      .v-expansion-panel {
        background-color: transparent;
      }
      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 0 12px 16px;
          .description-header {
            font-size: 16px;
          }
          .description-content {
            font-size: 14px;
          }
        }
      }
      .v-expansion-panel {
        margin-bottom: 1px;
      }
    }
  }
}
</style>
