<template>
  <div>
    <Breadcrumb :items="breadcrumb" />

    <v-container>
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
        @goToCustomerVote="goToCustomerVote"
      />
    </v-container>
    <v-container>
      <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + product.productId + '_key_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
    </v-container>

    <div class="grey lighten-1">
      <v-container>
        <ProductListSlider
          :productId="product.productId"
          :layout="220"
          :key="product.productId + '_3'"
          :paginationClass="'swiper-pagination-linea-220'"
          :showArrows="!$vuetify.breakpoint.xsOnly"
          :showBullets="$vuetify.breakpoint.xsOnly"
          position="product_variations"
        >
          <h2 class="secondary--text my-3">
            {{ $t("product.oftenBoughtTogether") }}
          </h2>
        </ProductListSlider>
      </v-container>
    </div>
    <v-container>
      <ProductListSlider
        :productId="product.productId"
        :layout="223"
        :key="product.productId + '_4'"
        :paginationClass="'swiper-pagination-linea-223'"
        :showArrows="!$vuetify.breakpoint.xsOnly"
        :showBullets="$vuetify.breakpoint.xsOnly"
        position="product_variations"
      >
        <h2 class="secondary--text mb-3">
          {{ $t("product.recommendedForYou") }}
        </h2>
      </ProductListSlider>
    </v-container>

    <!-- default layout 220 -> Altri clienti hanno acquistato: -->
    <category-block
      :target="category"
      :productId="product.productId"
      position="product1"
      class="category-block category-block-1"
    />

    <!-- default: crm layout 223 -> Ti potrebbe interessare... -->
    <category-block
      :target="category"
      :productId="product.productId"
      position="product2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.product-detail-view {
  img.normal {
    height: 360px;
    width: 360px;
  }
  img.image-mobile {
    height: 336px;
    width: 336px;
  }
  .thumb-wrapper {
    img {
      width: 80px;
      height: 80px;
    }
  }
  .back-button {
    &:hover {
      opacity: 0.5;
    }
  }
  .description-column {
    min-height: 296px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      min-height: 504px;
    }
  }
}
</style>
<script>
import join from "lodash/join";
import compact from "lodash/compact";
import get from "lodash/get";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        // aggregateRating: {
        //   reviewCount: this.product.ratingSummary.total,
        //   ratingValue: this.product.ratingSummary.avg
        //   // bestRating: // ex: 5,0
        // },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      layout20length: 0
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb,
    ProductListSlider
    // ,
    // CategoryBlock
  },
  computed: {
    breadcrumb() {
      return this.$store.getters["category/categoryPathById"](
        this.product.categoryId
      );
    },
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        // if (this.product.categoryId) {
        //   this.setCategory(this.product.categoryId);
        // }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        analyticsService.viewProductDetail(this.product);
      } else {
        this.showNoProductCard = true;
      }
    },
    loaded(productsFound) {
      this.layout20length = productsFound;
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      await this.getProduct();
      this.key += 1;
    },
    goToCustomerVote() {
      // goToCustomerVote;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    this.slug = this.$route.params.slug;
    this.setProduct(this.$route.params.product);
    // this.showNoProductCard = false;
    // await this.getProduct();
    // analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);
        // await this.getProduct();
        // analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    if (this.product) {
      const title =
        "Vendita " +
        this.product.name +
        " e all'ingrosso. " +
        this.breadcrumb[this.breadcrumb.length - 1].text +
        " all'ingrosso - Gala";
      return {
        title: title,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}/product/${this.product.slug}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.category.name +
                " in offerta e promozione su Gala con consegna in azienda e ritiro in negozio."
            )
          },
          {
            vmid: "keywords",
            name: "keywords",
            content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: title
          },
          {
            property: "og:type",
            content: "product"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            property: "og:image:alt",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.category.name +
                " in offerta e promozione su Gala con consegna in azienda e ritiro in negozio."
            )
          },
          {
            property: "og:site_name",
            content: "Gala"
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: title
          },
          {
            name: "twitter:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
