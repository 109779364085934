<template>
  <div v-if="rendered">
    <ul>
      <li
        v-for="(option, idx) in rendered.options"
        :key="idx"
        v-html="option.text"
      ></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: {} };
  },
  created() {
    this.rendered = JSON.parse(this.content);
  }
};
</script>
