<template>
  <div v-if="rendered.length">
    <div v-for="item in rendered" :key="item.key">
      <div v-for="(warning, idx) in getWarnings(item['items-map'])" :key="idx">
        <div v-html="`${warning.name}:`" class="font-weight-bold" />
        <div v-html="warning.value" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: { type: String, required: true }
  },
  data() {
    return { rendered: [] };
  },
  methods: {
    getWarnings() {
      let keyList = [
        "productIdentifier",
        "warnings",
        "safetyPhrases",
        "otherWarnings"
      ];
      let deserializedString = this.rendered;
      let itemsList = [];
      let warningsOk = false;

      for (let index = 0; index < deserializedString.length; index++) {
        let element = deserializedString[index]["items-map"];
        for (let key in element) {
          if (keyList.includes(key)) {
            if (element[key].value != null) {
              warningsOk = true;

              itemsList.push({
                name: element[key].name,
                value: element[key].value
              });
            }
          }
        }
      }

      if (!warningsOk) itemsList = null;
      if (itemsList.length < 2) {
        return itemsList[0].value;
      }
      return itemsList;
    }
  },
  created() {
    this.rendered = JSON.parse(this.content);
  }
};
</script>
